.background {
  height: 100vh;
  width: 100vw;
}

.sidebar {
  display: flex;
  transition: transform 0.4s;
}

.sidebar--show {
  transform: translateX(0);
}

.sidebar--hide {
  transform: translateX(-260px);
}

.collapse-arrow-wrapper {
  height: 70px;
  width: 50px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.collapse-arrow {
  border: solid black;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 8px;
}

.collapse-arrow--left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.collapse-arrow--right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.color-picker-wrapper {
  width: 220px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2);
}

.button-wrapper {
  margin-top: 20px;
}
